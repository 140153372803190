// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax';
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';
import './plugins/giant.cloner'
import './plugins/giant.mirror'
import './plugins/data-confirm'

import './plugins/giant.show-if'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

document.addEventListener("cloner:add", () => {
    [...document.querySelectorAll('form')].forEach(form => {
        Nette.initForm(form)
    })
})

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}



try {
    Object.assign({})
} catch (error) {

}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { loadComponents } from './core/componentUtils'
loadComponents()
