import scrollTo from './scrollTo';

const header = document.querySelector('.FixedHeader');

function getScrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop;
}

export default function scrollToElement(element, options = {offset: 20}) {
    const box = element.getBoundingClientRect();
    const scrollTop = getScrollTop();
    const headerOffset = header.getBoundingClientRect().height;

    const target = scrollTop + box.top - headerOffset - options.offset;

    scrollTo(target);
}