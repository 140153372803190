import { triggerEvent } from '../utils/dom'

document.addEventListener('change', function(event) {
        if (!event.target.matches('[data-mirror]')) return

        const element = event.target

        const mirrorInputs = [...document.querySelectorAll(`[data-mirror="${element.getAttribute('data-mirror')}"]`)]
        const sourceInput = mirrorInputs.find(mirrorInput => mirrorInput.hasAttribute('data-mirror-source'))

        if (!sourceInput) {
            console.warn(`[data-mirror-source] missing for data-mirror=[${element.getAttribute('data-mirror')}]`)
            return
        }

        if (element !== sourceInput) {
            return
        }

        mirrorInputs.forEach(mirrorInput => {
            if (mirrorInput === sourceInput) {
                return
            }
            //native changes
            mirrorInput.value = mirrorInput.tagName.toLowerCase() === 'select' ?  element.options[element.selectedIndex].value : element.value

            setTimeout(() => {
                triggerEvent(mirrorInput, 'change')
            }, 0)

            if (sourceInput && element.value) {
                mirrorInput.classList.add('is-mirrored')
            } else {
                mirrorInput.classList.remove('is-mirrored')
            }

            //custom handling - kvuli pluginum
            triggerEvent(mirrorInput, 'mirror:change', {
                newValue: mirrorInput.tagName.toLowerCase() === 'select' ?  element.options[element.selectedIndex].value : element.value,
                isMirrored: sourceInput && element.value
            })

        })
    })
