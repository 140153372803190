import Component from '../core/Component'
import EventBus from '../core/EventBus'

export const STATES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible'
}

export default class FixedHeader extends Component {

    constructor(element) {
        super(element)
        this.isActive = false

        this.ref = {
            title: null,
            counter: null
        }
    }

    prepare() {
        window.addEventListener("scroll",::this.handleScroll)
        EventBus.on('formstatement:formsection:change',::this.handleFormSectionChange)
    }

    destroy() {
        window.removeEventListener("scroll",::this.handleScroll)
        EventBus.off('formstatement:formsection:change')

    }

    handleScroll(event) {
        const offsetY = window.pageYOffset || document.documentElement.scrollTop
        
        if (offsetY > 150) {
            this.isActive = true
            this.element.classList.add(STATES.ACTIVE)
        } else {
            this.isActive = false
            this.element.classList.remove(STATES.ACTIVE)
        }
    }

    handleFormSectionChange(formSectionData) {
        if (formSectionData == null) {
            this.clearHeader()
            return
        }
        this.ref.counter.innerText = formSectionData.isFirst ? formSectionData.counter : ''
        this.ref.title.innerText = formSectionData.title
    }

    clearHeader() {
        this.ref.counter.innerText = ''
        this.ref.title.innerText = ''
    }
}