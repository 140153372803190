(function() {

    $(document).on('click', '[data-confirm]', function(event) {
        if (!confirm($(this).data('confirm'))) {
            event.preventDefault();
        }
    });

    //kdyz je treba ajax link, tak se nepropaguje click - kdyztak pouzit tuten fujfix
    $('[data-ajax-confirm]').on('click', function(event) {
        event.preventDefault()

        if (confirm($(this).data('ajaxConfirm'))) {
            $.nette.ajax({ url: this.href })
        }
    });

})();